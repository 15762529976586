function setEqualHeight() {
    var currentBreakpoint = getNumSkelSize();
    $(".prva-intro .grid-element-content").matchHeight();
    $(".grid-menu-item-016 .grid-element-title").matchHeight();
    $(".grid-news-item-010 article").matchHeight();

    $(".enaka-visina, .celice-enaka-visina > .grid-element > .grid-element-content").matchHeight();

    if (currentBreakpoint > 2) {
        $("#intro-text-box").matchHeight({
            target: $("#intro-image-box")
        });
    } else {
        $("#intro-text-box").matchHeight({
            remove: true
        });
    }
}

function setEqualSmallNews() {
    var currentBreakpoint = getNumSkelSize(),
        main_news_height,
        secondary_news_height;
    if (currentBreakpoint >= 3) {
        main_news_height = $("#main-news-wrapper article").innerHeight();
        secondary_news_height = (main_news_height - 30) / 3;
        $(".grid-news-item-010-01 .news-image-content a").css("height", secondary_news_height + "px");
        $(".grid-news-item-010-01 .news-image-content").css("height", secondary_news_height + "px");
        $(".grid-news-item-010-01 .news-content").css("height", secondary_news_height + "px");
    } else {
        $(".grid-news-item-010-01 .news-image-content a").css("height", "");
        $(".grid-news-item-010-01 .news-image-content").css("height", "");
        $(".grid-news-item-010-01 .news-content").css("height", "");
    }

}
$(window).on("load", function() {
    setEqualHeight();
    setEqualSmallNews();
});
$(window).on("resize", function() {
    $.fn.matchHeight._update();
    setEqualSmallNews();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});
